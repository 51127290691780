/* General header modules */
.module-header-sm {
  min-height: 250px;
  padding-top: 160px;
}

.module-header-md {
  min-height: 400px;
  padding-top: 170px;
}

.module-header-lg {
  min-height: 600px;
  padding-top: 150px;
}

.module-header-xl {
  min-height: 1050px;
  padding-top: 160px;
}


/* Header colours */
.header-block-gray {
  background-position: center top;
  background-image: url('/assets/img/header-gray.png');
  background-repeat: no-repeat;
  background-color: $header-gray;
  background-size: auto 600px;
}

.header-block-orange {
  background-image: url('/assets/img/header-orange.png');
  background-position: center top;
  background-repeat: no-repeat;
  background-color: $header-orange;
  background-size: auto 600px;
}

.header-block-light {
  background-image: url('/assets/img/header-light.png');
  background-color: $header-light;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: auto 600px;
}

.header-block-white {
  background-image: url('/assets/img/header-white.png');
  background-color: white;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: auto 1050px;
}

.header-block-purple {
  background-image: url('/assets/img/header-purple.png');
  background-color: $header-purple;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: auto 600px;
}
