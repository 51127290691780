/* Component adjustments */
.navbar {
  background-color: rgba(255, 255, 255, 1);
  transition: background 200ms linear;
  border-bottom: 1px solid $gray-400;
  // backdrop-filter: blur(4px);  /* makes the animation too slow */

  .logo-white {
    display: none;
  }
  .logo-primary {
    display: block;
  }

  .nav-link {
    color: $gray-600;
    transition: color 200ms linear;
  }

  .active .nav-link {
    color: $primary;
    font-weight: 500;
  }

  @include media-breakpoint-up(lg) {
    background-color: rgba(255, 255, 255, 0.95);
    border-bottom: 0;

    &.navbar-transparent:not(.navbar-transparent-cancel)  {
      background-color: rgba(255, 255, 255, 0);

      .nav-link {
        color: white;
      }
      .active .nav-link {
        color: white;
      }
      .logo-white {
        display: block;
      }
      .logo-primary {
        display: none;
      }
    }
  }

  .navbar-nav {
    flex-grow: 1; /* this makes the navbar take all available space, allowing us to right-align the "sign in" button */
  }

  a {
    font-weight: 200;
  }

  .product-description {
    display: none;

    @include media-breakpoint-up(lg) {
      width: 325px;
      white-space: normal;
      display: block;
    }
  }

  .nav-icon {
    width: 60px;
    display: flex;
    align-items: center;
  }

  .integration-logos img {
    height: 30px;
  }

  .feature-col {
    width: 250px;
  }
}
