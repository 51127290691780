$brand-orange: #ff5f12;
$brand-purple: #5229bf;
$brand-red: #c45640;
$brand-gray: #ababab;

// Set our colours
$primary: $brand-orange;
$info: $brand-purple;
$gray: #808080;
$gray-warm: #fef7f4;
$light: #f7f7f7;
$text-body: #56616d;
$text-headers: #333333;

// Other
$footer: #3a3a3a;
$header-orange: $brand-red;
$header-purple: $brand-purple;
$header-gray: #818181;
$header-light: #acacac;
$header-homepage: #f6f8fc;

// Make our site a little wider
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1236px
) !default;

// Add some more spacing
$spacers: (
  6: 7rem
);

// Font
$font-family-base: 'Nunito Sans', sans-serif;


// Required
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

// Optional
@import "bootstrap/scss/root";
@import "bootstrap/scss/reboot";
@import "bootstrap/scss/utilities";
@import "bootstrap/scss/grid";
@import "bootstrap/scss/nav";
@import "bootstrap/scss/navbar";
@import "bootstrap/scss/images";
@import "bootstrap/scss/type";
@import "bootstrap/scss/utilities";
@import "bootstrap/scss/buttons";
@import "bootstrap/scss/dropdown";
@import "bootstrap/scss/list-group";
@import "bootstrap/scss/carousel";
@import "bootstrap/scss/card";
@import "bootstrap/scss/forms";
@import "bootstrap/scss/input-group";
@import "bootstrap/scss/badge";
@import "bootstrap/scss/transitions";

// Load up styling for each custom page
@import "common";
@import "navbar";
@import "modules-headers";
@import "modules-footer";
@import "modules";
