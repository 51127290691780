html {
  font-size: 15px;
}

h1, .h1 {
  font-size: 3.5em;
  font-weight: 300;
}

h2, .h2 {
  font-weight: 300;
  font-size: 2.6em;
  color: $text-headers;
}

h3, .h3 {
  font-weight: 300;
  font-size: 2.2em;
  color: $text-headers;
}

h4, .h4 {
  font-size: 2em;
  font-weight: 400;
  color: $text-headers;
}

h5, .h5 {
  font-weight: 400;
  font-size: 1.3rem;
  color: $text-headers;
}

h6, .h6 {
  color: $gray;
  text-transform: uppercase;
  font-weight: 600;
}

body {
  font-weight: 200;
  color: $text-body;
}

p.lead-md {
  font-size: 1.15rem;
}

p.lead {
  font-size: 1.5rem;
  line-height: 2.5rem;
  font-weight: 200;
}

.btn {
  font-weight: 200;
}

a {
  font-weight: 400;
}

/* Theme all the blockquotes */
blockquote {
  padding: 1.5em 4em;
  margin: 1rem 0;
  border-left: 1px solid $primary;
  background-color: $light;

  p {
    font-size: 1.5rem;
    line-height: 2.5rem;
    font-weight: 200;
    color: $primary;
  }

  p:last-child {
    margin-bottom: 0;
  }
}

.bg-gray-warm {
  background-color: $gray-warm;
}

.bg-half-half {
  background: linear-gradient(0, white 50%, $light 50%);
}

.bg-half-half-reverse {
  background: linear-gradient(0, $light 50%, white 50%);
}

.container-max-sm {
  max-width: 540px;
}

.container-max-md {
  max-width: 720px;
}

.container-max-lg {
  max-width: 960px;
}

.user-markdown-content {
  img {
    max-width: 100%;
    height: auto;
    border: 1px solid $gray-400;
    padding: 3px;
  }

  h3 {
    font-size: 1.8em;
  }
  h4 {
    font-size: 1.5em;
  }
  h5 {
    font-size: 1.3em;
  }
}
