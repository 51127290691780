footer {
  background-color: $footer;
  color: white;

  li {
    padding: .8em 0 0 0;
  }

  a {
    color: white;
  }

  strong {
    font-weight: 600;
  }

  .keep-in-touch {
    font-size: 2.5em;
    font-weight: 200;
  }

  .footer-logo {
    max-width: 200px;
    width: 100%;
  }
}
